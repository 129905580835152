import gsap from 'gsap';
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';

interface SplittingResults {
	chars: HTMLSpanElement[];
	el: HTMLElement;
	words: HTMLSpanElement[];
}

export const splitData = (duration: number, stagger: number) => {
	const Splitting = require('splitting');

	const splittingResults: SplittingResults[] = Splitting({ target: '[data-splitting]', by: 'chars' });
	const characters = splittingResults.reduce((item, { chars }) => [...item, ...chars], []);

	if (splittingResults.length > 0) {
		gsap.timeline().set(characters, { opacity: 0, y: '40%', rotation: -4 }, 'start').staggerTo(
			characters,
			duration,
			{
				ease: 'Power3.easeOut',
				y: '0%',
				opacity: 1,
				rotation: 0,
			},
			stagger
		);
	}
};
